import React from "react";
import style from "./PrivacyPolicy.module.css";

const PrivacyPolicyItem = ({ title, text }) => {
  return (
    <div className={style.privacyPolicy__item}>
      <div className={style.privacyPolicy__item__title}>{title}</div>
      <div className={style.privacyPolicy__item__text}>{text}</div>
    </div>
  );
};

export default PrivacyPolicyItem;
