import React from "react";
import style from "./Footer.module.css";
import CustomNavLink from "../../Elements/CustomNavLink";
import { Button } from "@mui/material";

const Footer = () => {
  return (
    <div className={style.Footer}>
      <p>© 2023 Volens LLC. All rights reserved.</p>

      <div>
        <CustomNavLink to="/privacy-policy">
          <Button variant="text" size="medium" color="customColor">
            Privacy Policy
          </Button>
        </CustomNavLink>
        <CustomNavLink to="/terms-of-service">
          <Button variant="text" size="medium" color="customColor">
            Terms of Service
          </Button>
        </CustomNavLink>
      </div>
    </div>
  );
};

export default Footer;
