import React from "react";
import style from "./WhyChooseTab.module.css";
import Advantage from "./Advantages";

const WhyChooseTab = () => {
  return (
    <div className={style.why_choose_tab}>
      <div className={style.why_choose_tab__background}></div>
      <h2 className={style.why_choose_tab__header}>
        Why Choose
        <br />
        <span className={style.why_choose_tab__header__red}>Volens</span>?
      </h2>
      <div className={style.why_choose_tab__body}>
        <Advantage
          title={"Quality Materials"}
          text={
            "We use only the finest materials to ensure the durability and longevity of our upfits."
          }
        />
        <Advantage
          title={"Customization Options"}
          text={"Our upfits can be tailored to fit your exact needs and specifications."}
        />
        <Advantage
          title={"Expert Craftsmanship"}
          text={
            "Our team of skilled professionals ensures precision and attention to detail in every upfit we produce."
          }
        />
      </div>
    </div>
  );
};

export default WhyChooseTab;
