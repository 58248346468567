import React from "react";
import style from "./PrivacyPolicy.module.css";
import PrivacyPolicyBlock from "./PrivacyPolicyBlock";

const PrivacyPolicy = () => {
  return (
    <div className={style.privacyPolicy}>
      <h2 className={style.privacyPolicy__header}>Privacy Policy</h2>
      <PrivacyPolicyBlock
        title={"Introduction"}
        itemTitle1={"Information Collection"}
        itemText1={
          "We collect personal information when you fill out our contact form or place an order for our products. This information may include your name, email address, phone number, billing address, and shipping address."
        }
        itemTitle2={"Information Usage"}
        itemText2={
          "We use the collected information to provide and improve our services, process your orders, respond to your inquiries, and keep you updated with relevant information."
        }
        itemTitle3={"Information Protection"}
        itemText3={
          "We take necessary measures to ensure the security of your personal information. We do not share your information with third parties unless it is necessary for providing our services."
        }
      />
      <PrivacyPolicyBlock
        title={"Cookies"}
        text={
          "We use cookies to enhance your browsing experience on our website. Cookies are small text files stored on your device that track your preferences and activities on our site."
        }
        itemTitle1={"Opting Out"}
        itemText1={
          "You can choose to disable cookies in your browser settings. However, please note that some features of our website may not function properly without cookies."
        }
        itemTitle2={"Third-Party Cookies"}
        itemText2={
          "We may also use third-party cookies to gather statistical information and analyze user behavior on our website. This helps us improve our services and provide you with a better experience."
        }
      />
      <PrivacyPolicyBlock
        title={"External Links"}
        text={
          "Our website may contain links to external sites that are not operated by us. Please be aware that we have no control over the content and privacy policies of these sites."
        }
        itemTitle1={"Policy Changes"}
        itemText1={
          "We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be indicated at the top of the page."
        }
        itemTitle2={"Contact Us"}
        itemText2={
          "If you have any questions or concerns regarding our Privacy Policy, please contact us."
        }
      />
    </div>
  );
};

export default PrivacyPolicy;
