import React from "react";
import CustomNavLink from "../../Elements/CustomNavLink";
import style from "./Banner.module.css";
import { Button } from "@mui/material";

const Banner = () => {
  return (
    <div className={style.Banner}>
      <div className={style.text}>
        <h2>Chassis with Upfit for your Business</h2>
        <p>
          Working with RAM, Mercedes, Ford, Freightliner, HINO, INTL, Mack, ISUZU trucks with our
          high-quality upfits.
        </p>
        <div className={style.buttons}>
          <Button
            variant="outlined"
            size="large"
            color="customColor"
            onClick={() => (window.location.href = "#contact")}
          >
            Contact Us
          </Button>

          <CustomNavLink to="/products">
            <Button variant="contained" size="large" color="customColor">
              Learn More
            </Button>
          </CustomNavLink>
        </div>
      </div>
      <div className={style.image}>
        <img className={style.logo} src="/images/logo_volens.png" alt="Volens logo" />
      </div>
    </div>
  );
};

export default Banner;
