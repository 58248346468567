import React, { useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { IconButton } from "@mui/material";
import CustomNavLink from "../CustomNavLink";
import { Button } from "@mui/material";
import style from "./MobileMenu.module.css";

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={style.MobileMenu}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={handleClick}
        sx={{
          mr: 2,
          display: {
            xs: "block",
            sm: "none"
          }
        }}>
        <MenuRoundedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}>
        <MenuItem onClick={handleClose}>
          <CustomNavLink to="/">
            <Button variant="text" size="medium" color="customColor">
              Home
            </Button>
          </CustomNavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CustomNavLink to="/products">
            <Button variant="text" size="medium" color="customColor">
              Products
            </Button>
          </CustomNavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CustomNavLink to="/#contact">
            <Button variant="text" size="medium" color="customColor">
              Contacts
            </Button>
          </CustomNavLink>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MobileMenu;
