import React from "react";
import style from "./ProductTab.module.css";
import Prod from "./Prod";

const ProductTab = () => {
  return (
    <div className={style.prod_tab}>
      <h2 className={style.prod_tab__header}>Our Products</h2>
      <div>Browse through our wide range of upfits for your truck.</div>
      <div className={style.prod_tab__body}>
        <Prod img={"/images/ProductTab/productTab1.png"} title={"Aluminum Boxes"} />
        <Prod img={"/images/ProductTab/productTab2.png"} title={"Sleepers"} />
        <Prod img={"/images/ProductTab/productTab3.png"} title={"Tent Boxes"} />
        <div className={style.prod_tab__more}>AND MANY OTHERS</div>
      </div>
    </div>
  );
};

export default ProductTab;
