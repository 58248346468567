import React from "react";
import { NavLink } from "react-router-dom";
import style from "./CustomNavLink.module.css";

const CustomNavLink = ({ to, children }) => {
  return (
    <NavLink to={to} className={style.customNavLink}>
      {children}
    </NavLink>
  );
};

export default CustomNavLink;
