import React from "react";
import style from "./TermsOfService.module.css";
import PrivacyPolicyBlock from "../PrivacyPolicy/PrivacyPolicyBlock";
import text from "./text";

const TermsOfService = () => {
  return (
    <div className={style.TermsOfService}>
      <div className={style.TermsOfService__header}>
        <h2 className={style.TermsOfService__header__title}>Our Terms of Service</h2>
        <div className={style.TermsOfService__header__text}>Please read these terms carefully.</div>
      </div>
      <PrivacyPolicyBlock
        title={"Content"}
        itemTitle1={"Agreement"}
        itemText1={"By accessing the website, you agree to abide by these terms."}
        itemTitle2={"Updates"}
        itemText2={"We reserve the right to update these terms at any time."}
      />
      <div className={style.TermsOfService__body}>{text}</div>
    </div>
  );
};

export default TermsOfService;
