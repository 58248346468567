import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    customColor: {
      main: "#000000",
      contrastText: "#FFFFFF"
    }
  }
});

export default theme;
