import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Router from "./Pages/Router";
import Header from "./Components/Header";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Elements/ScrollToTop";
import style from "./App.module.css";
import theme from "./Theme";

const App = () => {
  return (
    <div className={style.App}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <ScrollToTop />
          <Router />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
