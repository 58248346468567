import React from "react";
import style from "./Sleepers.module.css";

const Sleeper = ({ img, atl, text1, text2, status }) => {
  return (
    <div className={style.sleeper}>
      <div className={style.sleeper__status}>{status ? "Available" : "Not Available"}</div>
      <img className={style.sleeper__img} src={img} alt={atl} />
      <div className={style.sleeper__description}>{text1}</div>
      <div className={style.sleeper__description}>{text2}</div>
    </div>
  );
};

export default Sleeper;
