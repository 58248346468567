import React from "react";
import style from "./Brands.module.css";

const Brand = ({ img, title }) => {
  return (
    <div className={style.brand}>
      <img className={style.brand__img} src={img} alt={title + "brand"} />
      <div className={style.brand__title}>{title}</div>
    </div>
  );
};

export default Brand;
