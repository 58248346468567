import React from "react";
import style from "./PrivacyPolicy.module.css";
import PrivacyPolicyItem from "./PrivacyPolicyItem";

const Block = ({
  title,
  text,
  itemTitle1,
  itemText1,
  itemTitle2,
  itemText2,
  itemTitle3,
  itemText3
}) => {
  return (
    <div className={style.privacyPolicy__block}>
      <div className={style.privacyPolicy__block__header_body}>
        <h2 className={style.privacyPolicy__block__header}>{title}</h2>
        {text && <div className={style.privacyPolicy__block__header_text}>{text}</div>}
      </div>
      <div className={style.privacyPolicy__block__body}>
        <PrivacyPolicyItem title={itemTitle1} text={itemText1} />
        <PrivacyPolicyItem title={itemTitle2} text={itemText2} />

        {itemTitle3 && itemText3 && <PrivacyPolicyItem title={itemTitle3} text={itemText3} />}
      </div>
    </div>
  );
};

export default Block;
