import React from "react";
import style from "./Brands.module.css";
import Brand from "./Brand";

const BrandsComponent = () => {
  return (
    <div className={style.brands}>
      <h2 className={style.brands__header}>Truck Brands We Serve</h2>
      <div className={style.brands__body}>
        <Brand img={"/images/Brands/intr.png"} title={"INTL"} />
        <Brand img={"/images/Brands/mers.png"} title={"Mercedes"} />
        <Brand img={"/images/Brands/ford.png"} title={"Ford"} />
        <Brand img={"/images/Brands/ram.png"} title={"RAM"} />
        <Brand img={"/images/Brands/freg.png"} title={"Freightliner"} />
        <Brand img={"/images/Brands/mack.png"} title={"Mack"} />
        <Brand img={"/images/Brands/isuzu.png"} title={"ISUZU"} />
      </div>
    </div>
  );
};

export default BrandsComponent;
