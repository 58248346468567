import React from "react";
import style from "./Map.module.css";

const Map = () => {
  return (
    <div className={style.map}>
      <iframe
        title="qwerr"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.4752523191223!2d-81.50713879999999!3d41.29852369999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88311fe525d4de41%3A0xc6adda9ac3d4bbb2!2s480%20Highland%20Rd%2C%20Macedonia%2C%20OH%2044056!5e0!3m2!1sen!2sus!4v1721181127956!5m2!1sen!2sus"
        width="600"
        height="450"
        className={style.map__iframe}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default Map;
