import React from "react";
import style from "./Header.module.css";
import { useWindowSize } from "../../Hooks";
import CustomNavLink from "../../Elements/CustomNavLink";
import MobileMenu from "../../Elements/MobileMenu";
import { Button } from "@mui/material";

const Header = () => {
  const { width } = useWindowSize();
  return (
    <div className={style.Header}>
      <div>
        <CustomNavLink to="/">
          <img className={style.logo} src="/images/logo_volens.png" alt="Volens logo" />
        </CustomNavLink>
      </div>
      <div>
        {width >= 768 ? (
          <div className={style.buttons_row}>
            <CustomNavLink to="/">
              <Button variant="text" size="large" color="customColor">
                Home
              </Button>
            </CustomNavLink>
            <CustomNavLink to="/products">
              <Button variant="text" size="large" color="customColor">
                Products
              </Button>
            </CustomNavLink>
            <CustomNavLink to="/#contact">
              <Button variant="text" size="large" color="customColor">
                Contacts
              </Button>
            </CustomNavLink>
          </div>
        ) : (
          <MobileMenu />
        )}
      </div>
    </div>
  );
};

export default Header;
