import React from "react";
import style from "./Products.module.css";
import Boxes from "../../Components/Boxes";
import SleepersComponent from "../../Components/Sleepers";
import AddOptionsComponent from "../../Components/AddOption";

const Products = () => {
  return (
    <div className={style.Products}>
      <Boxes
        title="Aluminum Boxes"
        img1="/images/Boxes/Aluminum/aluminum1.png"
        img2="/images/Boxes/Aluminum/aluminum2.png"
        statusFirst={true}
        statusSecond={true}
      />
      <Boxes
        title="Tent Boxes"
        img1="/images/Boxes/Tent/tent1.png"
        img2="/images/Boxes/Tent/tent2.png"
        statusFirst={true}
        statusSecond={true}
      />
      <SleepersComponent />
      <AddOptionsComponent />
    </div>
  );
};

export default Products;
