import React from "react";
import style from "./AddOptions.module.css";

const AddOption = ({ img, title, text }) => {
  return (
    <div className={style.add_option}>
      <img className={style.add_option__img} src={img} alt={title + "option"} />
      <div>
        <h1 className={style.add_option__title}>{title}</h1>
        <div className={style.add_option__text}>{text}</div>
      </div>
    </div>
  );
};

export default AddOption;
