import React from "react";
import style from "./ProductTab.module.css";

const Prod = ({ img, title }) => {
  return (
    <div className={style.prod}>
      <img className={style.prod__img} src={img} alt={title + "brand"} />
      <div className={style.prod__title}>{title}</div>
    </div>
  );
};

export default Prod;
