import React from "react";
import AddOption from "./AddOption";
import style from "./AddOptions.module.css";

const AddOptionsComponent = () => {
  return (
    <div>
      <h2 className={style.add_options__header}>Additional Upfit Options</h2>
      <div className={style.add_options__body}>
        <AddOption
          img={"/images/AddOptions/addOption1.jpeg"}
          title={"Air Ride System"}
          text={
            "It offers more consistency and keeps all wheels even. The air suspension system keeps trucks level from side to side, especially in cases where cargo is difficult to level. This results in reduced body roll when turning corners and curves."
          }
        />
        <AddOption
          img={"/images/AddOptions/addOption2.png"}
          title={"Electric Power Lifting Landing Gears"}
          text={
            "Manual cranking is now a thing of the past! More comfort – even when accessibility of the landing gear is limited."
          }
        />
        <AddOption
          img={"/images/AddOptions/addOption3.png"}
          title={"Hose for sleeper heating & cooling"}
          text={"Heating & cooling for your comfort."}
        />
        <AddOption
          img={"/images/AddOptions/addOption4.jpeg"}
          title={"Extra Toolbox"}
          text={"More space for you."}
        />
        <AddOption
          img={"/images/AddOptions/addOption5.jpeg"}
          title={"Large Extra Toolbox"}
          text={"More space for you."}
        />
      </div>
    </div>
  );
};

export default AddOptionsComponent;
