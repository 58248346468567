import React from "react";
import style from "./ContactInfo.module.css";

const ContactTab = ({ title, text, icon }) => {
  return (
    <div className={style.contactInfo__contactTab}>
      <div className={style.contactInfo__contactTab__icon}>
        <img src={`../icons/${icon}.png`} alt={icon}></img>
      </div>
      <div>
        <div className={style.contactInfo__contactTab__title}>{title}</div>
        <div className={style.contactInfo__contactTab__text}>{text}</div>
      </div>
    </div>
  );
};

export default ContactTab;
