import React from "react";
import style from "./Boxes.module.css";

const Boxes = ({ title, img1, img2, statusFirst, statusSecond }) => {
  return (
    <div className={style.boxes}>
      <h1 className={style.boxes__title}>{title}</h1>
      <div className={style.boxes__card}>
        <div className={style.boxes__status}>{statusFirst ? "Available" : "Not Available"}</div>
        <img className={style.boxes__img} src={img1} alt={title + "box"} />
      </div>
      <div className={style.boxes__card}>
        <div className={style.boxes__status}>{statusSecond ? "Available" : "Not Available"}</div>
        <img className={style.boxes__img} src={img2} alt={title + "car"} />
      </div>
    </div>
  );
};

export default Boxes;
