import React from "react";
import ContactTab from "./ContactTab";
import style from "./ContactInfo.module.css";

const ContactInfo = () => {
  return (
    <div id="contact" className={style.contact_info}>
      <h2 className={style.contact_info__header}>Contact Information</h2>
      <div>
        <ContactTab
          title={"Address"}
          text={"480 Highland Rd. E, Macedonia OH 44056"}
          icon={"location"}
        />
        <ContactTab title={"Phone Number"} text={"+1 (330) 357-1150"} icon={"phone"} />
        <ContactTab title={"Email"} text={"volens2015@gmail.com"} icon={"letter"} />
      </div>
    </div>
  );
};

export default ContactInfo;
