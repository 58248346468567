import React from "react";
import style from "./WhyChooseTab.module.css";

const Advantage = ({ title, text }) => {
  return (
    <div className={style.advantage}>
      <img className={style.advantage__icon} src={"/images/like.png"} alt={"like img"} />
      <div className={style.advantage__body}>
        <div className={style.advantage__title}>{title}</div>
        <div className={style.advantage__text}>{text}</div>
      </div>
    </div>
  );
};

export default Advantage;
