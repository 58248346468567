import React, { useEffect } from "react";
import Banner from "../../Components/Banner";
import BrandsComponent from "../../Components/Brands";
import ProductTab from "../../Components/ProductTab";
import WhyChooseTab from "../../Components/WhyChooseTab";
import Map from "../../Components/Map";
import ContactInfo from "../../Components/ContactInfo";
import { useLocation } from "react-router-dom";
import style from "./Home.module.css";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const anchor = document.getElementById(location.hash.substring(1));
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className={style.home}>
      <Banner />
      <BrandsComponent />
      <ProductTab />
      <WhyChooseTab />
      <Map />
      <ContactInfo />
    </div>
  );
};

export default Home;
