import React from "react";
import Sleeper from "./Sleeper";
import style from "./Sleepers.module.css";

const SleepersComponent = () => {
  return (
    <div className={style.sleepers}>
      <h2 className={style.sleepers__title}>Sleepers</h2>
      <div className={style.sleepers__body}>
        <Sleeper
          img={"/images/Sleepers/sleeper1.png"}
          alt={"sleeper1"}
          text1={"Extra space for your comfort with two sides windows."}
          text2={"Sleeper Model B"}
          status={true}
        />
        <Sleeper
          img={"/images/Sleepers/sleeper2.png"}
          alt={"sleeper2"}
          text1={"Tailored to your specific needs with one upper window."}
          text2={"Sleeper Model A"}
          status={true}
        />
      </div>
    </div>
  );
};

export default SleepersComponent;
